import styled, { css } from 'styled-components';
import { useState, useRef, useLayoutEffect } from 'react';
import { motion, useTransform, useViewportScroll } from 'framer-motion';
import CoverSvg from '../assets/macbook/macbook-cover.svg';
import TopSvg from '../assets/macbook/macbook-top.svg';
import BottomSvg from '../assets/macbook/macbook-bottom.svg';

const Container = styled.div<{ downShift: number }>`
  text-align: center;
  padding-bottom: 16px;
  transition: 0ms transform;
  will-change: transform;
  transform: ${({ downShift }) => `translateY(${downShift}px)`};
`;

const mockupStyles = css`
  display: inline-block;
  position: relative;
  z-index: 3;
  text-align: center;
  font-size: 0;
  perspective: 2400px;
  perspective-origin: 50% 100%;
`;

// const Part = styled.div`
const Part = styled(motion.div)`
  display: inline-block;
  position: relative;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transform-origin: 50% 100%;
  transition: 900ms;
  will-change: transform;
`;

const Top = styled(Part)<{ rotation: number }>`
  transition: 0ms;
  /* closed lid: -90deg
     opened lid: 0deg */
  transform: ${({ rotation }) =>
    `translate3d(0, 0, 0) rotateX(${rotation}deg)`};
`;

const OpenedLidCanvas = styled.div`
  ${mockupStyles}
`;

const Bottom = styled(Part)`
  position: absolute;
  top: 0;
  left: 0;
  transform: translate3d(0, 0, 0) rotateX(-90deg);
`;

const Img = styled.img`
  ${mockupStyles}
  display: block;
  max-width: 100%;
  backface-visibility: hidden;
`;

const TopImg = styled(Img)`
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: 50% 0;
  transition: 900ms;
  transform: translate3d(0, 0, -11px) rotateX(90deg) scale(1, 1);
  filter: brightness(0.5); /* darkens the image */
`;

const BottomImg = styled(Img)`
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: 50% 0;
  transform: translate3d(0, 0, 0) rotateX(90deg);
  filter: brightness(0.5);
`;

const Cover = styled(Img)`
  position: relative;
`;

const ContentImg = styled(Img)`
  object-fit: contain;
  display: block;
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  border-radius: 6px;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 1px);
`;

export default function OpenLaptopAnimation() {
  const ref = useRef<HTMLDivElement>(null);
  const { scrollY } = useViewportScroll();
  const [scrollStartsAt, setScrollStartsAt] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(0);
  const rotation = useTransform(
    scrollY,
    [scrollStartsAt - 650, scrollStartsAt + scrollHeight / 2],
    [-90, 0]
  );
  const [rotationState, setRotationState] = useState(-90);
  rotation.onChange(() => setRotationState(rotation.get()));

  const downShift = useTransform(
    scrollY,
    [scrollStartsAt - 650, scrollStartsAt + scrollHeight / 20],
    [-480, 0]
  );
  const [downShiftState, setDownShiftState] = useState(-480);
  downShift.onChange(() => setDownShiftState(downShift.get()));

  // useLayoutEffect(() => {
  //   if (ref.current) {
  //     setScrollHeight(ref.current.scrollHeight);
  //   }
  // }, [ref]);

  useLayoutEffect(() => {
    function calcAndSetScrollYOffset(): void {
      if (ref.current) {
        // TODO: split in two functions and orchestrate the calls
        setScrollHeight(ref.current.scrollHeight);
        // elements assume their next parent with
        // position: 'relative' as their position.top = 0.
        // To correctly calculate, we need to sum all positions
        // of parent elements with position: relative
        // eslint-disable-next-line prefer-destructuring
        let current: HTMLElement | null = ref.current;
        let scrollYOffset = current.getBoundingClientRect().top;
        current = current.parentElement;
        while (current) {
          if (current.style.position === 'relative') {
            scrollYOffset += current.getBoundingClientRect().top;
          }
          current = current.parentElement;
        }
        setScrollStartsAt(scrollYOffset);
      }
    }
    calcAndSetScrollYOffset();
    window.addEventListener('resize', calcAndSetScrollYOffset);
    return () => window.removeEventListener('resize', calcAndSetScrollYOffset);
  }, [ref]);

  return (
    <Container ref={ref} downShift={downShiftState}>
      <OpenedLidCanvas>
        <Top rotation={rotationState}>
          <TopImg src={TopSvg} className='top' />
          <Cover src={CoverSvg} alt='' className='cover' />
          <ContentImg
            src={
              // eslint-disable-next-line global-require
              require('../assets/works/mockup-macbook.png').default
            }
            alt=''
          />
        </Top>
        <Bottom>
          <Cover src={CoverSvg} alt='' className='cover' />
          <BottomImg src={BottomSvg} alt='' className='bottom' />
        </Bottom>
      </OpenedLidCanvas>
    </Container>
  );
}
